
import Vue from 'vue';
import i18n from '@/core/plugins/i18n';

export default class AppDashboardDefault extends Vue {
  //Data
  seq = 0;
  delays = 80;
  durations = 500;
  chart5: any = {
    data: {
      labels: [
        1,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        30
      ],
      series: [[3, 6, 9, 9, 7, 4, 7, 9, 7, 5, 1, 6, 3, 1, 5, 2, 9, 5, 9, 6, 3, 8, 8, 3, 6, 8, 4, 4, 3, 9]]
    },
    options: {
      low: 0,
      showArea: true
    }
  };
  chart6: any = {
    data: {
      labels: [
        1,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        30
      ],
      series: [
        [
          700,
          1000,
          100,
          500,
          300,
          400,
          500,
          500,
          800,
          600,
          800,
          1000,
          800,
          200,
          300,
          200,
          300,
          200,
          900,
          600,
          1000,
          700,
          800,
          400,
          500,
          200,
          800,
          600,
          300,
          800,
          600
        ]
      ]
    },
    options: {
      low: 0,
      showArea: true
    }
  };
  chart10: any = {
    data: {
      labels: [
        i18n.t('general.january'),
        i18n.t('general.february'),
        i18n.t('general.march'),
        i18n.t('general.april'),
        i18n.t('general.may'),
        i18n.t('general.june'),
        i18n.t('general.july'),
        i18n.t('general.august'),
        i18n.t('general.september'),
        i18n.t('general.october'),
        i18n.t('general.november'),
        i18n.t('general.december')
      ],
      series: [[0], [9, 17, 19, 44, 11, 60, 41, 59, 61, 56, 46, 14]]
    },
    options: {
      fullWidth: true,
      chartPadding: {
        right: 40
      }
    }
  };
  chart11: any = {
    data: {
      labels: [
        i18n.t('general.january'),
        i18n.t('general.february'),
        i18n.t('general.march'),
        i18n.t('general.april'),
        i18n.t('general.may'),
        i18n.t('general.june'),
        i18n.t('general.july'),
        i18n.t('general.august'),
        i18n.t('general.september'),
        i18n.t('general.october'),
        i18n.t('general.november'),
        i18n.t('general.december')
      ],
      series: [[0], [9, 17, 19, 44, 11, 60, 41, 59, 61, 56, 46, 14]]
    },
    options: {
      fullWidth: true,
      chartPadding: {
        right: 40
      }
    }
  };
}
